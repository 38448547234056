import React from 'react';
import Box from '@mui/material/Box';


export default function NoRecorComponent() {
    return (
        <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            <div>
                <div className='no-record'></div>
            </div>            
        </Box>

    )

}