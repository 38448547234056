// import { Bar } from "react-chartjs-2";
// import {
//     Chart as chartJs, 
//     CategoryScale, 
//     LinearScale, 
//     BarElement, 
//     Title, 
//     Tooltip, 
//     Legend
// } from "chart.js"


// chartJs.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip, 
//     Legend
// )

import Chart from 'react-apexcharts'
interface ProjectsCardProps {
    chartType: "horizontal-bar" | "donut" | "stacked-bar" | "department",
    strength: {
        total: number,
        occupied: number,
        free: number
    },
    stacks:{
        stacks_name: string[] ,
        list: number[]
    },
    projects: {
        total: number,
        active: number,
        nonActive: number
    },   
    department:{
        department_name: string[] ,
        list: number[]
    },  
  }



// const barChartData = {
//     labels: ["On Time", "Minor Delays", "Delayed"],
//     datasets: [
//         {
//             label: "Prjects",
//             data: [18, 6, 2],
//             backgroundColor: "red",
//             borderColor: "blue",
//             borderWidth: 1
//         }
//     ]
// }

const state = {
    options: {}
}

const horizontalBarChartOptions = {
    chart: {
        toolbar: { show: false }
    },
    dataLabels: {
        enabled: true
      },
    plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50%',  // Adjust this value to increase or decrease the gap between bars
        },
    },
    legend: { 
        show: true,
        position: 'right',
        markers: {
            shape: 'circle',
            offsetX: -5// Adds space between the marker and the text
        },
        horizontalAlign: 'left',
        itemMargin: {
            horizontal:6,
            vertical: 6    // Adds vertical space between rows if needed
        },
        formatter: function(seriesName:any, opts:any) {
            // Use custom formatter to create a flex layout with space between
            const value = opts.w.globals.series[opts.seriesIndex];
            return `<div style="display: flex; justify-content: space-between; width: 100px;">
                        <span>${seriesName}</span>
                        <span><b>${value}</b></span>
                    </div>`;
        }
    },
    tooltip:{
        fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: -100,
            offsetY: 0,
        },
    },
    grid: {
        show: false, // This removes the grid lines
        padding: {
            top: -80,
        }, 
    },
    xaxis: {
        labels:{
            show:false,
        },
    },
    yaxis: {
        show:false,
        axisBorder: {
            show: false,
        },
        axisTicks:{
            show: false,
        }
    },
    colors: ["#02FAB8", "#FFDE30", "#FF3263"],  // Different colors for each series
    
}

const stackedChartOptions = {
    chart:{
        stacked: true,
        toolbar: { show: false }
    },
    dataLabels: {
        enabled: true
    },
    legend: { 
        show: true,
        position: 'right',
        markers: {
            shape: 'circle',
            offsetX: -5 // Adds space between the marker and the text
        },
        itemMargin: {
            horizontal:8,
            vertical: 6    // Adds vertical space between rows if needed
        },
        formatter: function(seriesName:any, opts:any) {
            // Use custom formatter to create a flex layout with space between
            const value = opts.w.globals.series[opts.seriesIndex];
            return `<div style="display: flex; justify-content: space-between; width: 150px;">
                        <span>${seriesName}</span>
                        <span><b>${value}</b></span>
                    </div>`;
        }
    },
    tooltip:{
        fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: -100,
            offsetY: 0,
        },
    },
    plotOptions: {
        bar: {
            columnWidth: '65%'
        },
    },
    xaxis: {
        labels:{
            show:false,
        },
        axisBorder: {
            show: false,
        },
        axisTicks:{
            show: false,
        }
    },
    yaxis: {
        show:false,
        axisBorder: {
            show: false,
        },
        axisTicks:{
            show: false,
        },
    },
    grid: {
        show: false, // This removes the grid lines
        padding: {
            top: -30,
        }, 
    },
    colors: ["#676DFA", "#02FAB8", "#FF3263"],  // Different colors for each series
}

function ProjectsChart({chartType, strength, stacks, projects, department} : ProjectsCardProps) {

    const semiDonutChartOptions = {
        chart: {
            type: 'donut',
            toolbar: { show: false }
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90, // This makes it a semi-donut
                offsetY: 10
                }
        },
        legend: { 
            show: true,
            position: 'left',
            itemMargin: {
                vertical: 8    // Adds vertical space between rows if needed
            },
            markers: {
                shape: 'circle',
                offsetX: -10 // Adds space between the marker and the text
            },
            formatter: function(seriesName:any, opts:any) {
                // Use custom formatter to create a flex layout with space between
                const value = opts.w.globals.series[opts.seriesIndex];
                return `<div style="display: flex; justify-content: space-between; width: 50px;">
                            <span>${seriesName}</span>
                            <span><b>${value}</b></span>
                        </div>`;
            }
        },
        grid: {
            padding: {
                top: 100,
                right: 0,
                bottom: 0,
                left: 0
            }, 
        },
        labels:  stacks.stacks_name, // Adding names for each series
        // colors: ["#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF',
        //     "#676DFA", "#02FAB8", "#FF3263", '#FFDE30', '#FC5FFF', '#1AF1FF'
        // ],   // Different colors for each series
    
    }

    const semiDepartmentDonutChartOptions = {
        chart: {
            type: 'donut',
            toolbar: { show: false }
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90, // This makes it a semi-donut
                offsetY: 10
                }
        },
        legend: { 
            show: true,
            position: 'left',
            itemMargin: {
                vertical: 8    // Adds vertical space between rows if needed
            },
            markers: {
                shape: 'circle',
                offsetX: -10 // Adds space between the marker and the text
            },
            formatter: function(seriesName:any, opts:any) {
                // Use custom formatter to create a flex layout with space between
                const value = opts.w.globals.series[opts.seriesIndex];
                return `<div style="display: flex; justify-content: space-between; width: 50px;">
                            <span>${seriesName}</span>
                            <span><b>${value}</b></span>
                        </div>`;
            }
        },
        grid: {
            padding: {
                top: 100,
                right: 0,
                bottom: 0,
                left: 0
            }, 
        },
        labels:  department?.department_name
    }

    let series : any;
    let type : "bar" | "donut" = "bar";
    let height;
    if (chartType === 'horizontal-bar') {
        series = [{
            name: 'Total Projects',
            data: [{
                x: "",
                y: projects?.total
            }]
          }, {
            name: 'Active Projects',
            data: [{
                x: "",
                y: projects?.active
            }]
          }, {
            name: 'Non Active Projects',
            data: [{
                x: "",
                y: projects?.nonActive
            }]
          }]
        type = "bar";
        height = 230;
        state.options = horizontalBarChartOptions

    } else if (chartType === 'donut') {
        series = stacks?.list
        type = "donut";
        height = 600;
        state.options = semiDonutChartOptions

    } else if (chartType === 'department') {        
        series = department?.list
        type = "donut";
        height = 600;
        state.options = semiDepartmentDonutChartOptions
 
    }else if (chartType === 'stacked-bar') {
        series = [            
          {
            name: 'Occupied',
            data: [{
                x: 'Occupied',
                y: strength?.occupied
            }]
          }, {
            name: 'Free(On Bench)',
            data: [{
                x: 'Free',
                y: strength?.free
            }]
          }]
        type = "bar";
        height = 200;
        state.options = stackedChartOptions

    }
    return (
        // <Bar options={options} data={barChartData}>Chart</Bar>
        <Chart options={state.options} series={series} type={type} height={height} width='330' />
    )
}

export default ProjectsChart