import React from "react"
import ResponsiveAppBar from "../Dashboard/components/appbar"

export function WorkLog () {
    return (
        <div className='mainApp'>
            <ResponsiveAppBar />

            <iframe title="JIRA with Excel" width="960" height="600"  allowFullScreen src="
                https://app.powerbi.com/view?r=eyJrIjoiZGE2OThiMTktMzdlMi00M2FlLTk5ZGItY2M2ZDg0MmVlMzU2IiwidCI6ImE1MjNlYzQwLTVlYmYtNGRmZC1iNjQwLTQxYmI4YjQ5Nzg1NiIsImMiOjl9"            >
            </iframe>
        </div>
    )
}