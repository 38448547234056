import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, TextField, Typography, Switch, Avatar, Grid, Checkbox, Autocomplete, CircularProgress } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import { toast } from 'react-toastify';
interface BookingProps {
    open: boolean,
    handleClose: (status: boolean) => void, // Update handleClose type
    bookingData : any,
    resourceData : any[],
    projectsData : any[],
    fetchResources: any,
    fetchEventsData: any,
  }

function BookingModal({ open, handleClose, bookingData, resourceData, projectsData, fetchResources, fetchEventsData }: BookingProps) {

  const [activeTab, setActiveTab] = useState(0); // Manage active tab (0 for Booking, 1 for Time Off)
  const [isBillable, setIsBillable] = useState(false);
  const [allocatedHours, setallocatedHours] = useState(0);
  const [freeHours, setfreeHours] = useState(0);
  const [selectedResource, setSelectedResource] = useState<any>(null); // Handle selected resource
  const [selectedProject, setSelectedProject] = useState<any>(null); // Handle selected project
  const [selectedRole, setSelectedRole] = useState<any>(null); // Handle selected role
  const [details, setDetails] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loader, setloader] = useState(false);
  const [deleteLoader, setDeleteloader] = useState(false);

  // Validation error states
  const [errors, setErrors] = useState({
    resource: false,
    project: false,
    role: false,
    startDate: false,
    endDate: false,
    allocatedHours: false
  });

  useEffect(() => {
    console.log('bookingData',bookingData)

    if (open) {
      // Reset the form fields when the modal opens
      if (bookingData.id) {
        const resource = resourceData.find((res) => res.id === bookingData.ResourceId);
        setSelectedResource(resource); 
        setallocatedHours(bookingData.allocatedHours)
        setfreeHours(resource.freeHours)
        setSelectedProject(bookingData.projects);
        setSelectedRole(bookingData.role);
        setStartDate(bookingData.startDate ? bookingData.startDate.split('T')[0] : undefined);
        setEndDate(bookingData.startDate ? bookingData.endDate.split('T')[0] : undefined);
        setIsBillable(bookingData.billable);
        setDetails(bookingData.details ? bookingData.details : "");
      } else if(bookingData.dateStr){
        setStartDate(bookingData.dateStr ? bookingData.dateStr : undefined);
      } else {
        // Reset values if no bookingData is provided
        setSelectedResource(null);
        setSelectedProject(null);
        setSelectedRole(null);
        setIsBillable(false);
        setDetails("");
        setStartDate(undefined);
        setEndDate(undefined);
      }
  
      setErrors({
        resource: false,
        project: false,
        role: false,
        startDate: false,
        endDate: false,
        allocatedHours: false,
      });
    }
  }, [open, bookingData]);

  // Validate all fields before submission
  const validateFields = () => {
    const newErrors = {
      resource: selectedResource === null,
      project: selectedProject === null,
      role: selectedRole === null,
      startDate: startDate === undefined,
      endDate: endDate === undefined,
      allocatedHours: allocatedHours > freeHours,
    };
    setErrors(newErrors);

    // Return true if no errors
    return !Object.values(newErrors).some(error => error);
  };

    // Validate all fields before submission
    const validateFieldsUpdate = () => {
      const newErrors = {
        resource: selectedResource === null,
        project: selectedProject === null,
        role: selectedRole === null,
        startDate: startDate === undefined,
        endDate: endDate === undefined,
        allocatedHours: false
      };
      setErrors(newErrors);
  
      // Return true if no errors
      return !Object.values(newErrors).some(error => error);
    };

  const stringAvatar = (resource:any) => ({
    sx: {
        width:40,
        height:40,
        bgcolor: resource.lightColor ? resource.lightColor : '#5257C71A',
        color: resource.darkColor ? resource.darkColor : '#5257C7B2',
        border: `1px solid ${resource.darkColor ? resource.darkColor : '#5257C7B2'}`,
        borderRadius: '50%',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '19.2px'
    },
    children: `${resource.firstName?.charAt(0)}${resource.lastName?.charAt(0)}`,
  });

  // Handle the submission of booking modal
  const handleAddBooking = async () => {
    if (!validateFields()) {
      return;
    }

    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);

    const payload = JSON.stringify({
      "projectId":selectedProject.id,
      "resourceId":selectedResource.id,
      "startDate":startDate,
      "endDate":endDate, 
      "billable":isBillable ? 1 : 0, 
      "details":details ? details : "", 
      "role": selectedRole,
      "allocatedHours": allocatedHours
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
    };
    setloader(true);
    const api_url = process.env.REACT_APP_API_URL;    
    await fetch(api_url + "events", requestOptions)
    .then((response) => response.json())
    .then((result) => {
        updateFreeHours(selectedResource.freeHours -allocatedHours)
        toast.success("Schedule Added Successfully")        
        setloader(false)
        handleClose(true); // Close modal after submission
    })
    .catch((error) => {
        toast.error(error)
        setloader(false)
        handleClose(false); // Close modal after submission
    });

  };

  const handleUpdateBooking = async () => {
    if (!validateFieldsUpdate()) {
      return;
    }

    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);

    const payload = JSON.stringify({
      "projectId":selectedProject.id,
      "resourceId":selectedResource.id,
      "startDate":startDate,
      "endDate":endDate, 
      "billable":isBillable ? 1 : 0, 
      "details":details ? details : "", 
      "role": selectedRole,
      "allocatedHours": allocatedHours
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: payload,
    };
    setloader(true);
    const api_url = process.env.REACT_APP_API_URL;    
    await fetch(api_url + `events/${bookingData.id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
        updateFreeHours(selectedResource.freeHours -allocatedHours)
        // toast.success("Event Updated Successfully")        
        // setloader(false)
        handleClose(true); // Close modal after submission
    })
    .catch((error) => {
        toast.error(error)
        setloader(false)
        handleClose(false); // Close modal after submission
    });

  };

  const handleDeleteBooking = async () => {

    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "token " + token);

    const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    };
    setDeleteloader(true); 
    const api_url = process.env.REACT_APP_API_URL;    
    await fetch(api_url + `events/${bookingData.id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
        updateFreeHours(selectedResource.freeHours + allocatedHours)
        toast.success("Schedule Deleted Successfully")        
        setDeleteloader(false)
        handleClose(false); // Close modal after submission
    })
    .catch((error) => {
        toast.error(error)
        setDeleteloader(false)
        handleClose(false); // Close modal after submission
    });    

  };

  const updateFreeHours = async (free_Hours:number) => {
    console.log('set updated hours',free_Hours)
    
    const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "token " + token);
  
      const payload = JSON.stringify({
        "freeHours":free_Hours
      });
      
      const requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: payload,
      };
      setloader(true);
      const api_url = process.env.REACT_APP_API_URL
      await fetch(api_url + `resource/freeHours/${selectedResource.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
          setloader(false)
          if (result.status === "error"){
            toast.error(result.message)
          }else{
            toast.success("Schedule Updated Successfully")
            fetchResources();
            fetchEventsData(selectedResource);
          }
      })
      .catch((error) => {
          toast.error(error)
          setloader(false)
      });

  };

  return (
    <Modal open={open} onClose={()=>{handleClose(false)}}>
      <Box sx={{ 
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        height: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: '10px',
      }}>

          <div>
            <Box sx={{ mt: 3 }}>
                <Grid container>
                        <Grid item md={5.5}>
                          {/* Resource */}
                          <Typography sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '19.2px' }}>Resource  <span style={{color: 'red'}}>*</span></Typography>
                                    <Autocomplete
                                        options={resourceData}
                                        value={selectedResource}
                                        getOptionLabel={(resource: any) => `${resource.firstName} ${resource.lastName}`}
                                        onChange={(event, value) =>{ 
                                          setSelectedResource(value)
                                          if(value){
                                            setfreeHours(value.freeHours ? value.freeHours : 0)
                                            setallocatedHours(0)
                                          }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Select Resource" variant="outlined" margin="normal"
                                          error={errors.resource} helperText={errors.resource ? "Resource is required" : ""} fullWidth />}
                                        renderOption={(props, resource) => (
                                          <li {...props}>
                                              <Box display="flex" alignItems="center">
                                                  <Avatar {...stringAvatar(resource)} />
                                                  <Typography sx={{ ml: 2 }}>{resource.firstName} {resource.lastName}</Typography>
                                              </Box>
                                          </li>
                                      )}
                                    />

                                    
                                    {/* Role */}
                                    <Typography sx={{ mt: 2, fontSize: '18px', fontWeight: 600, lineHeight: '19.2px' }}>Role  <span style={{color: 'red'}}>*</span></Typography>
                                    <Autocomplete
                                        options={['Dev','Manager', 'QA', "UI/UX"]}
                                        value={selectedRole}
                                        onChange={(event, value) => setSelectedRole(value)}
                                        renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" margin="normal" 
                                        error={errors.role} helperText={errors.role ? "Role is required" : ""}fullWidth />}
                                    />

                                    {/* Project */}
                                    <Typography sx={{ mt: 2, fontSize: '18px', fontWeight: 600, lineHeight: '19.2px' }}>Project  <span style={{color: 'red'}}>*</span></Typography>
                                    <Autocomplete
                                        options={projectsData}
                                        value={selectedProject}
                                        getOptionLabel={(project: any) => project.projectName}
                                        onChange={(event, value) => setSelectedProject(value)}
                                        renderInput={(params) => <TextField {...params} label="Select Project" variant="outlined" margin="normal" 
                                        error={errors.project} helperText={errors.project ? "Project is required" : ""} fullWidth />}
                                    />
                        </Grid>
                        <Grid item md={5.5} sx={{paddingLeft:'12px'}}>
                            {/* Start Date & End Date */}
                            <Typography sx={{ fontSize:'18px',fontWeight:600,lineHeight:'19.2px', ml:1 }}>Start Date & End Date  <span style={{color: 'red'}}>*</span></Typography>
                            <Box display="flex" gap={2} sx={{ mt: 2, ml:1 }}>
                                <TextField label="From" value={startDate || ""} type="date" InputLabelProps={{ shrink: true }}   onChange={(e:any) => setStartDate(e.target.value)}
                                  error={errors.startDate}
                                  helperText={errors.startDate ? "Start date is required" : ""} />
                                <TextField label="To" value={endDate || ""} type="date" InputLabelProps={{ shrink: true }} onChange={(e:any) => setEndDate(e.target.value)} 
                                  error={errors.endDate}
                                  helperText={errors.endDate ? "End date is required" : ""}/>
                            </Box>

                            {/* Time */}
                            {selectedResource ? <Box display="flex" gap={2} sx={{ mt: 7.5, ml:1 }}>
                                <TextField label="Free Hours" type="number" value={freeHours} inputProps={{ min: 0 }} disabled />
                                <TextField label="Allocate Hours *"  type="number" value={allocatedHours} 
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);                                  
                                  setallocatedHours(value); // Update only if it's within freeHours limit
                                }}
                                error={errors.allocatedHours}
                                helperText={errors.allocatedHours ? 'Cannot exceed free hours' : ''}/>
                            </Box> : ''}

                            {/* Billable */}
                            <Box display="flex" alignItems="center" sx={{ mt: 8  }}>
                                <Switch checked={isBillable} onChange={(e) => setIsBillable(e.target.checked)}
                                 sx={{
                                  '& .MuiSwitch-thumb': {
                                    color: isBillable ? '#000000' : '',
                                  },
                                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#000000', // Track color when checked
                                  },
                                }}/>
                                <Typography>Billable</Typography>
                            </Box>
                        </Grid>
                </Grid>
              {/* Details */}
              <Typography sx={{ mt: 2, fontSize:'18px',fontWeight:600,lineHeight:'19.2px' }} >Details</Typography>
              <TextField label="Add Details" value={details} variant="outlined" fullWidth multiline rows={5} margin="normal" onChange={(e) => setDetails(e.target.value)}/>
             
              {bookingData.id ? 
              <Box sx={{ display: 'flex', justifyContent: 'end' , mt: 3, gap:2}}>
                <Button variant="outlined" style={{color:'black', borderColor:'#00000033', width:'20%'}} onClick={()=>{handleClose(false)}}>Cancel</Button>
                <Button variant="contained" style={{backgroundColor:'#676DFA', width:'20%'}} onClick={handleUpdateBooking} disabled={loader}>
                {loader ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Update Booking'}</Button>
                <Button variant="contained" color="error" style={{width:'20%'}} onClick={handleDeleteBooking} disabled={deleteLoader}>
                {deleteLoader ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Delete'}
                </Button>
              </Box>
              : <Box sx={{ display: 'flex', justifyContent: 'end' , mt: 3, gap:2}}>
                <Button variant="outlined" style={{color:'black', borderColor:'#00000033', width:'20%'}} onClick={()=>{handleClose(false)}}>Cancel</Button>
                <Button variant="contained" style={{backgroundColor:'#676DFA', width:'20%'}} onClick={handleAddBooking} disabled={loader}>
                {loader ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Add Booking'}</Button>
              </Box>}
            </Box>
          </div>
        
      </Box>
    </Modal>
  );
}

export default BookingModal;
