import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {CircularProgress, TextField, Typography} from '@mui/material';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


  
export default function AddStackAnchorDrawer(props: any) {    
  const [stack, setStack] = React.useState("");
  const [loader, setloader] = React.useState(false)

  
  const handleChange = (e: any) => {    
    setStack(e.target.value);
  };

  const handleSubmitStack = async () => { 
    if (stack === ""){
        toast.error("Stack name shouldn't be empty")
    }else{
        setloader(true)
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);

        const payload = JSON.stringify({        
            "name": stack
        })
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: payload,
        };
        const api_url = process.env.REACT_APP_API_URL;                    
        await fetch(api_url + "stack", requestOptions)
        .then((response) => response.json())    
        .then((result: any) => {
            if (result?.status === "error"){
                toast.error(result?.message)
                setloader(false);
            }else{
                const res = result["data"]
                let mainrowsData = props.mainRows;
                const setRowsData = props.setRows;
                const setMainRowsData = props.setMain;

                let new_row = {
                    id: res?.id, name: res?.name
                }
                mainrowsData.push(new_row)

                setRowsData(mainrowsData)  
                setMainRowsData(mainrowsData)  
                
                toast.success("Sucess")

                const setAnchor = props.setAnchor
                setAnchor(false)        
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    
  }


  return (
    <Box className='add-resource-anchor'>
        <Typography className='add-resource-text'>
            Add Stack           
        </Typography>

        <Box className='resource-form'>
            <Box className='fieldBox'>
                <Typography className='labelText'>Name  <span style={{color: 'red'}}>*</span></Typography>  
                <TextField className="textField" onChange={handleChange}></TextField>
            </Box>

            <Box className='buttonBox'>
                {loader ? (
                    <Button className='add-resource'>
                        <CircularProgress  color="inherit" />
                    </Button>
                ) : (
                    <Button className='add-resource' onClick={handleSubmitStack}>
                        Add Stack
                    </Button>
                )}
                
            </Box>
        </Box>

    </Box>
  );
}
