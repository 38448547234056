import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Card, CircularProgress, Divider, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
// import ReactApexChart from 'react-apexcharts';
import ProjectsChart from './projectChart';

function ProjectsCard({response, loader}: any) {
    
    return (
    
    <Grid spacing={0}>
        <Grid container className='cards-container'>
            <Grid item md={4}>
                <Card className='utilization-card' sx={{ padding: 2, borderRadius: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{height:'100%'}}>

                        {/* <Grid item xs={5.5}>                           
                            <div style={{height:'100%'}}>

                                <ProjectsChart chartType={"department"} strength={response?.strength} stacks={response?.stacks} 
                                    projects={response?.projects} department={response?.department} />   
                                
                            </div>

                        </Grid> */}

                        {/* <Divider orientation="vertical" flexItem sx={{ mx: 2 }} /> */}

                        <Grid item xs={5.5}>
                                Total Strength : {response?.strength?.total}
                                <ProjectsChart chartType='stacked-bar' strength={response?.strength} stacks={response?.stacks} 
                                    projects={response?.projects} department={response?.department} />                                                        
                        </Grid>
                    </Grid>
                </Card>

            </Grid>
            <Grid item md={4}>
                <Card className='actual-hrs-card'>                    
                    <div>
              <div id="chart">
                <ProjectsChart chartType={"donut"} strength={response?.strength} stacks={response?.stacks} 
                    projects={response?.projects} department={response?.department} />   
              </div>
              <div id="html-dist"></div>
            </div>
                </Card>
            </Grid>
            <Grid item md={3}>
                <ProjectsChart  chartType='horizontal-bar' strength={response?.strength} stacks={response?.stacks} 
                    projects={response?.projects} department={response?.department} />
            </Grid>
        </Grid>
    </Grid>

  );
}

export default ProjectsCard;
