import React, { useEffect, useState } from 'react';
import './resources.css';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from '../Dashboard/components/appbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ResourceProjectTable from './Components/ResourceProjectTable';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, 
    Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ResourceCard from './Components/resourceCard';
import { fetchResourceDetail } from '../../api/resourcesAPIs/resourceApis';
import { getFullName } from '../../utils/utils.func';




function ResourceProjectDetails() {   

    const location = useLocation();
    const [resourceData, setResourceData] = React.useState<any>();
    const [projectData, setProjectData] = React.useState<any>();


    async function fetchResource(id: string){
        const result: any = await fetchResourceDetail(id);
        setResourceData(result.data);
        setProjectData(result.projects_list)        
    }



    useEffect( () => {
        const searchParams = new URLSearchParams(location.search);
        const resourceId = searchParams.get('resourceId');
        
        if (resourceId) {
            fetchResource(resourceId);
        }

    }, [])
  
  return (
    <Grid spacing={0} className='mainApp'>
            <ResponsiveAppBar />             

            <Box className='dash-container'>
                <Grid >
                    <Grid item md={1}></Grid>
                    <Grid item md={10} className='dashboard-header'>
                        <div className='titleBar'>
                            {getFullName(resourceData)}
                        </div>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>                    
            </Box>

            <Box className='card-container'>
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <ResourceCard resource = {resourceData} projects = {projectData}/>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>                    
            </Box>

            <Box className="tables-section">
                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <ResourceProjectTable projects={projectData}/>  
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                        
            </Box>


    </Grid>

  );
}

export default ResourceProjectDetails;
