import * as XLSX from 'xlsx';


const api_url = process.env.REACT_APP_API_URL;            
const token = localStorage.getItem('token');
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "token " + token);

const getrequestOptions = {
    method: "GET",
    headers: myHeaders
};


export const fetchAllManagers = async () => {
    let res;
    await fetch(api_url + `resource/getManagers`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result;
        })
        .catch((error) => {
            res = [];
        });        
    return res;
}

export const dashboardAPIs = async () => {
    let res;
    await fetch(api_url + `resource/dashboard`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {           
            res = result;            
        })
        .catch((error) => {
            res = [];
        });        
    return res;
}

export const fetchResourceDetail = async (id: string) => {
    let res;
    await fetch(api_url + `resource/detail/${id}`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {
            res = result;
        })
        .catch((error) => {
           res = {}
        });
    return res;
}

export const exportAllResources = async () => {
    await fetch(api_url + `resource/export/resources`, getrequestOptions)
        .then((response) => response.json())
        .then((result) => {
            const ws = XLSX.utils.json_to_sheet(result);

            // Create a new workbook and append the worksheet to it
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            // Generate the Excel file as a Blob
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the Excel buffer and trigger the download
            const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = 'Resources.xlsx'; // Name of the downloaded file
            link.click();
        })
        .catch((error) => {           
        })
}   