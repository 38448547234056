import * as React from 'react';
import { Avatar, CircularProgress, Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { toast } from 'react-toastify';
import NoRecorComponent from '../../../Components/NoRecordComponent';


const rows = [
  {
    id: 12, name: "Ahmed Khan",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"    
  },
  {
    id: 12, name: "Muhammad ALi",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"    
  },
  {
    id: 12, name: "Muhammad ALi",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"  
  },
  {
    id: 12, name: "Muhammad ALi",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"     
  },
  {
    id: 12, name: "Muhammad ALi",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"     
  },
  {
    id: 12, name: "Muhammad ALi",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"     
  },
  {
    id: 12, name: "Muhammad ALi",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"     
  },
  {
    id: 12, name: "Muhammad ALi",
    code: "MC12", 
    startDate: '10-14-24',
    endDate: '12-14-24',
    designation: "Senior Software Engineer",
    teamLead: "Mahad Akbar"     
  }
];

const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Resource',
    },
    {
      id: 'designation',
      numeric: false,
      disablePadding: false,
      label: 'Designation',
    },
    {
      id: 'stack',
      numeric: false,
      disablePadding: false,
      label: 'Stack',
    },
    {
        id: 'project',
        numeric: false,
        disablePadding: false,
        label: 'Project',
    },
    {
        id: 'engagement',
        numeric: false,
        disablePadding: false,
        label: 'Engagement',
    }
];
  

export default function ResourcesTable({resourcesArray}: any) {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [resources, setResourcesData] = useState([
    {
      id: null,
      firstName:'',
      lastName: '',
      email: '',
      phoneNumber: '',
      employmentStatus: '',
      department: '',
      jobTitle: {
        id: '',
        name: ''
      },
      stacks: '',
      notes: '',
      managerId: '',
      timeZone: ''
    }
  ]);

  const handleResourcePage = (resource:any) => {
    const params = {
      resourceId: resource.id
    };
    const queryString = new URLSearchParams(params).toString();
    navigate(`/resources-detail?${queryString}`);
  };

  useEffect( () => {
    setloader(true);
    setResourcesData(resourcesArray)
    setloader(false);
  }, [resourcesArray])


  function stringAvatar(name: string) {
    return {
      sx: {
        width:40,
        height:40,
        bgcolor: '#5257C71A',
        color: '#5257C7B2',
        border: '1px solid #5257C7B2',
        borderRadius: '50%',
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '19.2px'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }


  return (
    <div style={{display:'flex',justifyContent:'center'}}>
      {loader ? <CircularProgress size={24} color="inherit" /> :         
      <Grid container spacing={1}>
      {resources.length ? resources?.map((row, index) => {
        return (
          <Grid item md={2}>
            <Card className='resource-card' onClick={() => handleResourcePage(row)}>
              <CardContent>
                <Avatar {...stringAvatar(`${row.firstName} ${row.lastName}`)}  className='avatar-name'/>
                <br></br>
                <div className='resource-name'>{row.firstName} {row.lastName}</div>
                <div className='resource-designation'>{row?.jobTitle?.name}</div>
              </CardContent>
            </Card>
          </Grid>
        )
      }) : <NoRecorComponent />}     
      </Grid>}
    </div>

  );
}
