import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Protected(props: any) {
  const navigate = useNavigate();
  const { Component } = props;

  useEffect( () => {
    let token  = localStorage.getItem('token');
    if (!token){
        navigate('/login')
    }
  })

  return (  
    <Component />
  );
}

export default Protected;
