import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from '../Dashboard/components/appbar';
import { Box, Button, CircularProgress, Input, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import AddProjectAnchorDrawer from './Components/addProjects';
import ProjectsTable from './Components/projectsTable';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchAllManagers } from '../../api/resourcesAPIs/resourceApis';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { exportAllProject } from '../../api/projectAPIs/projectAPIs';

function Projects() {
    const navigate = useNavigate();
    const [anchor, setAnchor] = useState(false)
    const [loader, setloader] = useState(false);
    const [rowsData, setrowsData] = useState([{}])
    const [managersList, setManagersList] = React.useState([{
        id: "",
        firstName: "",
        lastName: ""
      }]); 
    const [projects, setProjectsData] = useState([
        {
          id: null,
          projectName: "",
          projectCode: "",
          startDate: "",
          endDate: "",
          isArchived: false,
          billable: false,
          details: "",
          pm_id: null,
          tl_id: null,
        }
      ])

    const handleStateAnchor = () => {
        setAnchor(true)
        return true;
    };

    const handleSearch = (e: any) => {
        const searchVal = e.target.value.toLowerCase();
        if (searchVal === "") {
            setrowsData(projects);
        } else {
            const filteredRows = projects.filter((row: any) => 
                row.projectName.toLowerCase().includes(searchVal)
            );
            setrowsData(filteredRows);
        }
    };

    const toggleDrawer = () => {
        setAnchor(false);
        fetchProjects()
        return false
    };

    const fetchManagers = async () => {
        const result = await fetchAllManagers();
        if (result){
          setManagersList(result)
        }
      }
    
    const handleExportProject = async () => {
        toast.info("Preparing Sheet ...");
        exportAllProject();
    }

    const fetchProjects = async () => {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);
        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        };
        setloader(true);
        const api_url = process.env.REACT_APP_API_URL;
        await fetch( api_url + "projects", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if(result?.message){
                toast.error(result.message)
                if (result.message == "Invalid token."){
                    navigate('/login')
                }               
            }
            else{
                setProjectsData(result)
                setrowsData(result)
                setloader(false)
            }
        })
        .catch((error) => {
            toast.error(error)
            setloader(false)
        });      
    }

    useEffect( () => {
        fetchProjects();
        fetchManagers();
    }, [])

 
  return (
    <Grid spacing={0}  className='mainApp'>
            <ResponsiveAppBar />  
            <Box>                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid container spacing={2} >
                            <Grid item md={6}>
                                <div className='titleBar'>
                                    Projects    
                                </div>
                            </Grid>
                            <Grid item md={3}>
                            <TextField
                                className='search-field'
                                placeholder='Search'
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                                    }}
                                />
                                
                            </Grid>
                            {/* <Grid item md={1}>
                                <Button className='add-resource'>
                                    <CloudUploadIcon /> &nbsp; Import
                                </Button>
                            </Grid> */}
                            <Grid item md={1}>
                                <Button className='add-resource' onClick={handleExportProject}>
                                    <CloudDownloadIcon /> &nbsp; Export
                                </Button>
                            </Grid>
                            
                            <Grid item md={2}>
                                <Button className='add-resource' onClick={handleStateAnchor}>
                                    Add Project
                                </Button>
                                <Drawer
                                    anchor={'right'}
                                    open={anchor}
                                    onClose={toggleDrawer}
                                >                                    
                                    <AddProjectAnchorDrawer toggleDrawer={toggleDrawer} manager_list={managersList}/>
                                </Drawer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                    
            </Box>   


            <Box className="table-section">
                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid>
                            <Grid item md={12} sm={12} xs={12}>
                            {loader && projects ? <div  style={{display:'flex',justifyContent:'center'}}>
                                <CircularProgress size={24} color="inherit" /> 
                            </div>:  <ProjectsTable projectsArray={rowsData} updateProjectList={fetchProjects} manager_list={managersList}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                    
            </Box>                 

    </Grid>

  );
}

export default Projects;
