import React from 'react';
import './login.css';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';



function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setloader] = useState(false);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value)
  }

  const handleSubmit = async () => {

    if (email === ""){
      alert("Email shouldn't be empty")
    }
    else if (password === ""){
      alert("Password shouldn't be empty")
    }
    else{      
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const payload = JSON.stringify({
        "email": email,
        "password": password
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payload,
      };
      setloader(true);
      const api_url = process.env.REACT_APP_API_URL;
      
      await fetch( api_url + "auth/login", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          
          if(result.user){
            localStorage.setItem("token", result.token)
            localStorage.setItem("name", result.user?.name)
            localStorage.setItem("email", result.user?.email)
            toast.success("Successfully Logged In")
            navigate('/')
          }
          else{
            toast.error("Invalid Credentials")
            setloader(false)  
          }
        })
        .catch((error) => {
          toast.error("Invalid Credentials")
          setloader(false)
        });      
    }
  }

  return (  
    <Grid container className='login'>
        <Grid item md={6} sm={12} xs={12} className='textSection'>
        </Grid>

        <Grid item md={6} sm={12} xs={12} className='signInSection'>
          <div className='signIn'>Welcome Back</div>
          <div className='signIn-desc'>Please login to proceed</div>

          <div className='login-section'>
            <div className='name-section'>
              <div className="label">Name</div>
              <TextField className='textfield' onChange={handleEmailChange} placeholder="Write your name"
              InputProps={{
                style: {
                  borderRadius: 10,
                },
              }}></TextField>
            </div>

            <div className='password-section'>
              <div className="label">Password
              </div>
              <TextField 
                className='textfield' 
                type={'password'} 
                onChange={handlePasswordChange}   
                placeholder="Your Password here"    
                InputProps={{
                  style: {
                    borderRadius: 10,
                  },
                }}         
              ></TextField>
            </div>

            <div className='forgot-section'>
              <label className='rememberTxt'>
                <input type="checkbox" className='checkbox'/>
                Remember me
              </label>
              <span className='forgotTxt'>Forgot Password?</span>
            </div>
          </div>
          {
            loader ? (
              <Button className='loginBtn'>
                <CircularProgress  color="inherit" /></Button>
            ) : (
              <Button className='loginBtn' onClick={handleSubmit}>Login</Button>
            )
          }          
   
        </Grid>
      </Grid>
  );
}

export default Login;
