import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
import { getFullName } from '../../../utils/utils.func';
import { useNavigate } from 'react-router-dom';


const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Resource',
  },
  {
    id: 'department',
    numeric: true,
    disablePadding: false,
    label: 'Department',
  },
  {
    id: 'jobtitle',
    numeric: true,
    disablePadding: false,
    label: 'Job Title',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Employment Status',
  },
  {
    id: 'stack',
    numeric: true,
    disablePadding: false,
    label: 'Stacks',
  },
  {
    id: 'manager',
    numeric: true,
    disablePadding: false,
    label: 'Manager',
  },
];

function EnhancedTableHead() {

  return (
    <TableHead>
      <TableRow className='table-header'>

        <TableCell className='table-header-cell'
        ></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className='table-header-cell'
            key={headCell.id}            
            padding={headCell.disablePadding ? 'none' : 'normal'}          
          >
          {headCell.label}
        </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export default function EnhancedTable({response}: any) {
    const navigate = useNavigate();

    function EnhancedTableToolbar() {      
    
      return (
        <Toolbar
         
        >
          <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
              className='table-text'
            >
              Free Resource (On Bench) : <span style={{ color: "red"}}> {response?.free?.length}</span>
            </Typography>
          
        </Toolbar>
      );
    }
    
  function stringAvatar(name: string) {
    return {
      sx: {
        width:40,
        height:40,
        bgcolor: '#5257C71A',
        color: '#5257C7B2',
        border: '1px solid #5257C7B2',
        borderRadius: '50%',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '19.2px'
      },
    };
  }

  const handleResourcePage = (resource:any) => {
    const params = {
      resourceId: resource.id
    };
    const queryString = new URLSearchParams(params).toString();
    navigate(`/resources-detail?${queryString}`);
  };

  return (
    <Box sx={{ width: '100%', borderRadius:"30px" }}>
      <Paper sx={{ width: '100%', mb: 2 , borderRadius:"30px"}}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            sx={{ minWidth: 1000 , borderRadius:"30px"  }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead   />
            <TableBody>
              {response?.free?.map((row: any, index: any) => {                
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{ cursor: 'pointer',borderBottom: 'none !important' }}                    
                  >
                    <TableCell className='table-cell'></TableCell>
                    <TableCell
                      className='table-cell'
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      onClick={() => handleResourcePage(row)}
                    >
                      <div className='resource-cell'>
                        <Avatar {...stringAvatar(row.name)} />
                        {row.firstName + " " + row.lastName}
                      </div>
                    </TableCell>
                    <TableCell className='table-cell'>{row?.department?.name} </TableCell>
                    <TableCell className='table-cell'>{row?.jobTitle?.name} </TableCell>
                    <TableCell className='table-cell'>{row?.employmentStatus} </TableCell>
                    <TableCell className='table-cell-stack'>
                        {row?.Stacks?.map((val: any)  => <span className='stack-value'>{val?.name}</span>)}
                    </TableCell>
                    <TableCell className='table-cell-designation'>{getFullName(row?.manager)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>      
    </Box>
  );
}
