import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Card, Divider, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { fetchTotalAllocatedHours, 
     counBillableProjectsOfResources,
     counNonBillableProjectsOfResources} from '../../../utils/utils.func';


function ResourceCard({resource, projects}: any) {
   
  
  return (
    <Grid spacing={0}>
        <Grid container className='cards-container'>
            <Grid item md={5.7}>
                <Card className='utilization-card' sx={{ padding: 2, borderRadius: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{height:'100%'}}>

                        <Grid item xs={5.5}>
                            <div className='util-card-data'>
                                <div>
                                    <div className='card-title' style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                        Total Hours Allocated
                                    </div>
                                    <div className='card-hrs' style={{ fontSize: '2rem' }}>
                                        {fetchTotalAllocatedHours(projects)}
                                    </div>
                                </div>                             
                            </div>
                        </Grid>

                        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                        <Grid item xs={5.5}>
                            <div style={{height:'100%'}}>
                                <div style={{height:'50%'}}>
                                    <div className='billable-title' style={{ fontSize: '0.875rem', color: 'black' }}>
                                    Billable
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'15px'}}>
                                        <LinearProgress 
                                        variant="determinate"                                         
                                        value={(counBillableProjectsOfResources(resource?.Projects) / resource?.Projects?.length ) * 100}
                                        sx={{ width:'80%',height: 10, borderRadius: 5, backgroundColor: '#F8B6001A', '& .MuiLinearProgress-bar': { backgroundColor: '#F8B600B2' } }}
                                        />
                                        <div className='non-billable-hrs' style={{ color: 'black', fontWeight: '600' }}>
                                        {counBillableProjectsOfResources(resource?.Projects)}
                                    </div>
                                    </div>
                                </div>

                                <div style={{height:'50%'}}>
                                    <div className='non-billable-title' style={{ fontSize: '0.875rem', color: 'black', marginTop: '1rem' }}>
                                    Non Billable
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'15px'}}>
                                        <LinearProgress 
                                        variant="determinate"                                         
                                        value={(counNonBillableProjectsOfResources(resource?.Projects) / resource?.Projects?.length ) * 100}
                                        sx={{ width:'80%',height: 10, borderRadius: 5, backgroundColor: '#F8B6001A', '& .MuiLinearProgress-bar': { backgroundColor: '#F8B600B2' } }}
                                        />
                                        <div className='non-billable-hrs' style={{ color: 'black', fontWeight: '600' }}>
                                        {counNonBillableProjectsOfResources(resource?.Projects)}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Card>

            </Grid>
            <Grid item md={3}>
                <Card className='actual-hrs-card'>
                    <div className='card-data'>
                        <label className='card-title'>
                            Actual hours
                        </label>
                        <label className='card-hrs'>
                            200 hrs
                        </label>
                    </div>
                </Card>
            </Grid>
            <Grid item md={3}>
                <Card className='overtime-hrs-card'>
                    <div className='card-data'>
                        <label className='card-title'>
                            Overtime hours
                        </label>
                        <label className='card-hrs'>
                            200 hrs
                        </label>
                    </div>
                </Card>
            </Grid>
        </Grid>
    </Grid>

  );
}

export default ResourceCard;
