import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Card, Divider, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import UtilizationChart from '../../Dashboard/components/utilizationChart';
import { counBillableResourcesOfProjects, countNonBillableResourcesOfProjects, getBudgetedHoursProject } from '../../../utils/utils.func';
// import ReactApexChart from 'react-apexcharts';

function ProjectCardDetail({ project}: any) {
    // const totalHours = 1;  // Total budgeted hours
    // const billableHours = 1;
    // const nonBillableHours = 0;
    
    // // Calculate percentage for billable and non-billable hours
    // const billablePercentage = (billableHours / totalHours) * 100;
    // const nonBillablePercentage = (nonBillableHours / totalHours) * 100;
  
    // const state = {
          
    //     series: [44, 55, 41, 17, 15],
    //     options: {
    //       chart: {
    //         type: 'donut',
    //       },
    //       responsive: [{
    //         breakpoint: 480,
    //         options: {
    //           chart: {
    //             width: 200
    //           },
    //           legend: {
    //             position: 'bottom'
    //           }
    //         }
    //       }]
    //     },
      
    //   };

  return (
    <Grid spacing={0}>
        <Grid container className='cards-container'>
            <Grid item md={5.7}>
                <Card className='utilization-card' sx={{ padding: 2, borderRadius: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{height:'100%'}}>

                        <Grid item xs={5.5}>
                            <div className='util-card-data'>
                                <div>
                                    <div className='card-title' style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                        Budgeted Hours  (8hrs /day)
                                    </div> 
                                    <div className='card-hrs' style={{ fontSize: '2rem' }}>
                                        {getBudgetedHoursProject(project?.startDate, project?.endDate)}
                                    </div>
                                </div>
                                <div className='utilization'>
                                    {/* <h4>Utilization</h4>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <UtilizationChart value={60}/>
                                        </div>
                                        <div style={{ marginLeft: '10px', fontSize: '28px', fontWeight: '600', lineHeight:'33.6px' }}>
                                            80%
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            
                        </Grid>

                        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                        <Grid item xs={5.5}>
                            <div style={{height:'100%'}}>
                                <div style={{height:'50%'}}>
                                    <div className='billable-title' style={{ fontSize: '0.875rem', color: 'black' }}>
                                    Billable Resources
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'15px'}}>
                                        <LinearProgress 
                                        variant="determinate" 
                                        value={(counBillableResourcesOfProjects(project?.Resources) / project?.Resources?.length ) * 100}                                        
                                        sx={{ width:'80%',height: 10, borderRadius: 5, backgroundColor: '#6EEFAE33', '& .MuiLinearProgress-bar': { backgroundColor: '#06FF95' } }}
                                        />
                                        <div className='billable-hrs' style={{ color: 'black', fontWeight: '600'}}>
                                        {counBillableResourcesOfProjects(project?.Resources)}
                                        </div>
                                    </div>
                                </div>

                                <div style={{height:'50%'}}>
                                    <div className='non-billable-title' style={{ fontSize: '0.875rem', color: 'black', marginTop: '1rem' }}>
                                    Non Billable Resources
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'15px'}}>
                                        <LinearProgress 
                                        variant="determinate"                                         
                                        value={(countNonBillableResourcesOfProjects(project?.Resources) / project?.Resources?.length ) * 100}
                                        sx={{ width:'80%',height: 10, borderRadius: 5, backgroundColor: '#F8B6001A', '& .MuiLinearProgress-bar': { backgroundColor: '#F8B600B2' } }}
                                        />
                                        <div className='non-billable-hrs' style={{ color: 'black', fontWeight: '600' }}>
                                        {countNonBillableResourcesOfProjects(project?.Resources)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <ProjectsChart chartType='stacked-bar' /> */}
                        </Grid>
                    </Grid>
                </Card>

            </Grid>
            <Grid item md={3}>
                <Card className='actual-hrs-card'>
                    <div className='proj-card-data'>
                        <label className='card-title'>
                            Total Spent Hours
                        </label>
                        <label className='card-hrs'>
                            200 hrs
                        </label>
                    </div>

                    <div className='proj-card-data'>
                        <label className='card-title'>
                            Overtime
                        </label>
                        <label className='card-hrs'>
                            200 hrs
                        </label>
                    </div>
                    <div>
            </div>
                </Card>
            </Grid>
            <Grid item md={3}>
                <Card className='overtime-hrs-card'>
                    <div className='proj-card-data'>
                        <label className='card-title'>
                            Managed By
                        </label>
                        <label className='card-hrs'>
                           {project?.project_manager?.firstName + " " + project?.project_manager?.lastName}
                        </label>
                    </div>

                    <div className='proj-card-data'>
                        <label className='card-title'>
                            Team Lead
                        </label>
                        <label className='card-hrs'>
                            {project?.team_lead?.firstName + " " + project?.team_lead?.lastName}
                        </label>
                    </div>
                </Card>
            </Grid>
        </Grid>
    </Grid>

  );
}

export default ProjectCardDetail;
